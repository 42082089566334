import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ViewChild, HostListener } from "@angular/core";
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TextsService } from "../../services/texts/texts.service";
import { PageService, HeaderResponse, ORDER_REQ_TYPES, ResponseReqOrder } from "../../services/page.service";
import { UserSettingsService } from "../../services/user-settings.service";

import { Language } from "../language";
import { HistoryItem } from "../historyItem";

import { MenuComponent } from "../menu/menu.component";
import { HistoryComponent } from "../history/history.component";
import { state, transition, trigger, animate, style } from "@angular/animations";
import { Router } from "@angular/router";
import { urlPath } from "../../helpers";
import { Title } from "@angular/platform-browser";
import { CommonTextsService } from "../../services/texts/common-texts.service";
import { Contract } from "./header.classes";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    encapsulation: ViewEncapsulation.None,

    animations: [
        trigger("openClose", [
            state(
                "open",
                style({
                    height: "135px",
                    overflow: "visible",
                })
            ),
            state(
                "closed",
                style({
                    height: "73px",
                    overflow: "hidden",
                })
            ),
            transition("open => closed", [animate("0.5s")]),
            transition("closed => open", [animate("0.5s")]),
        ]),
    ],
})
export class HeaderComponent implements OnInit {
    texts: TextsService;

    event: string;
    role: string;
    evStatus: string;
    evStatusColor: string;
    closeTime: Date;
    contract: Contract;
    language: number;
    userName: string;
    messagesTxt: string;
    newMessages: number;
    languages: Language[];
    history: HistoryItem[];
    wsError: string;
    ajaxError: string;

    displayContactDlg: boolean = false;

    remainingTime: string;

    menuIsOpen;

    req_order: ResponseReqOrder;
    ORDER_REQ_TYPES = ORDER_REQ_TYPES;

    @Output() changeLanguageEvent = new EventEmitter<Language>();
    @Output() chooseHistoryEvent = new EventEmitter<number>();
    //@Output() tick = new EventEmitter<Date>();

    @ViewChild("menu", { static: true }) menu: MenuComponent;
    @ViewChild("historyComponent") historyComponent: HistoryComponent;
    @ViewChild("menuHist") menuHist;

    constructor(
        private pageService: PageService,
        private userSettingsService: UserSettingsService,
        private titleService: Title,
        private commonTextsService: CommonTextsService
    ) {
        this.texts = new TextsService();
    }

    ngOnInit() {
        this.userSettingsService.obsCloseEvent.subscribe(() => {
            this.pageService.getEventStatus().subscribe((response) => {
                this.processResponseEventStatus(response);
            });
        });
        this.userSettingsService.setActiveMenuObservable(this.menu.obsActiveMenu$);

        this.pageService.getPage().subscribe({
            next: (response) => {
                if (response.menu) {
                    this.menu.menus = response.menu;

                    //let activeUrl = window.location.pathname;
                    this.changeMenu();

                    this.menuIsOpen = true;
                }
                if (response.header) {
                    this.loadResponse(response.header);
                }
            },
            error: (error) => {
                console.debug(error.statusText);
            },
        });
    }

    changeMenu() {
        this.menu.setMenu();
        this.setTitle();
    }

    setTitle() {
        //bug 854
        let title = this.commonTextsService.t("app_title");
        // let title = '';
        if (this.menu.activeMenu) {
            //console.debug(this.header.menu.activeMenu);
            title += " - " + this.menu.activeMenu.name;
            if (this.menu.activeSubMenu) {
                title += " / " + this.menu.activeSubMenu.name;
            }
            this.titleService.setTitle(title);
        }
    }

    processResponseEventStatus(response) {
        this.event = response.event;
        this.role = response.role;
        this.evStatus = response.evStatus;
        this.evStatusColor = response.evStatusColor;
        //this.closeTime = response.closeTime;
        if (response.closeTime) {
            this.closeTime = new Date(response.closeTime);
        }
    }

    loadResponse(response: HeaderResponse) {
        //console.debug(response);
        if (response.eventStatus) {
            this.processResponseEventStatus(response.eventStatus);
        }
        if (response.event) {
            //for events evaluation
            this.event = response.event;
        }
        this.setContract(response.contract);
        this.language = response.language;
        this.languages = response.languages;
        this.userName = response.userName;
        this.userSettingsService.userName = this.userName;
        this.messagesTxt = response.messagesTxt;
        this.newMessages = response.newMessages;
        this.texts.texts = response.texts;
        this.req_order = response.req_order;

        this.userSettingsService.headerIsSet();

        // if(this.menu.menus) {
        //   this.menuIsOpen = true;
        // }

        // this.history = response.history;

        // console.log(this.languages);

        // this.history.forEach(item => { item.command = (event) => { //console.debug(event.item);
        //                                                            this.chooseHistoryEvent.emit(event.item.key);
        //                                                          };
        //                              });
    }

    setContract(contract: Contract) {
        // console.log(contract);
        this.contract = contract;
        this.userSettingsService.openContract = contract;
    }

    // @HostListener('window:scroll', ['$event'])
    toggleMenu(event = null) {
        // console.log(event);
        if (event) {
            //window scroll
            // this.menuIsOpen = window.pageYOffset <= 135;
            // console.log(window.pageYOffset);
            if (window.pageYOffset > 50 && window.pageYOffset <= 135) {
                this.menuIsOpen = true;
            } else if (window.pageYOffset > 135 && window.pageYOffset <= 170) {
                this.menuIsOpen = false;
            }
        } else {
            //click on menu switcher
            this.menuIsOpen = !this.menuIsOpen;
        }
    }

    get selectedLanguage(): String {
        if (this.languages) {
            // return this.languages.find( (language => language.key == this.language ));
            return this.languages.find((language) => language.key == this.language)?.country;
        }
    }

    //   set selectedLanguage(language: number) {
    //     this.language = language;
    //     // this.language = language.key;
    //   }

    showContactDlg() {
        this.displayContactDlg = true;
    }

    onTick(time: Date) {
        if (this.closeTime) {
            //console.debug(this.closeTime);
            //console.debug(time);
            //console.debug(this.closeTime.getTime() - time.getTime());

            this.remainingTime = this.userSettingsService.remainingTime(this.closeTime, true);
            /*
      let diffSecs= this.closeTime.getTime() - Math.floor(time.getTime()/1000)*1000;
      if(diffSecs >= 0) {
        this.remainingTime = new Date(diffSecs);
        //console.debug(this.remainingTime);
        if(diffSecs == 0) {
          this.remainingTime = null;
          this.closeTime = null;
          this.pageService.getPage(true)
            .subscribe(response => { this.evStatus = response.header.evStatus,
                                     this.evStatusColor = response.header.evStatusColor,
                                     this.closeTime = new Date(response.header.closeTime)
                                   });

        }
      }
      */
        }
    }

    changeLanguage(event) {
        this.language = event.value;
        let languageOption = this.languages.find((lang) => lang.key == event.value);
        // this.language = event.value.key;
        this.changeLanguageEvent.emit(languageOption);
    }

    showHistory(event) {
        if (!this.menuHist.visible) {
            this.pageService.getHistoryItems().subscribe((response) => {
                this.history = response;
                this.history.forEach((item) => {
                    item.command = (evt) => {
                        //console.debug(evt.item);
                        this.chooseHistoryEvent.emit(evt.item.key);
                    };
                });

                //this.menuHist.toggle(event);
            });
        }

        this.menuHist.toggle(event);
    }

    get languageName(): string {
        return this.languages?.find((language) => language.key == this.language)?.value;
    }
}
