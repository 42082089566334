import { createNgModuleRef, EventEmitter, Injectable, Injector, NgModuleRef, Output, Type } from "@angular/core";

export class DynamicModuleData {
    moduleRef: any;
    component: any;
}

@Injectable({
    providedIn: "root",
})
export class HomepageDynamicLoadService {
    constructor(private _injector: Injector) {}

    public dynamicLoad(moduleFolder: string, callback: Function) {
        if(!moduleFolder) {
            moduleFolder = HomepageDynamicLoadService.getLoginModule();
        }
        import(`../../modules/homepages/${moduleFolder}/login.module`)
            .then((importedFile) => {
                let module = importedFile.LoginModule || importedFile.DefaultModule;
                callback(this.getDynamicModuleData(module, module.components?.dynamicComponent));
            })
            .catch((err) => {
                console.info(err.message);
                console.info("fallback to standard login page");
                if (moduleFolder != "__standard") {
                    this.dynamicLoad("__standard", callback);
                }
            });
    }

    private getDynamicModuleData<T>(module: Type<T>, dynamicComponent: any): { component: any; moduleRef: NgModuleRef<T> } {
        const moduleRef: NgModuleRef<T> = createNgModuleRef(module, this._injector);
        return { component: dynamicComponent, moduleRef: moduleRef };
    }

    public static getLoginModule(): string {
        let hostname = window.location.hostname;

        let arrHostname = hostname.split(".");
        if (arrHostname[0] == "dev" || arrHostname[0] == "local") {
            arrHostname = arrHostname.slice(1);
            hostname = arrHostname.join(".");
        }

        let moduleName = hostname.replace(/\./g, "-");
        return moduleName;
    }
}
